<div class="main-container">

  <div class="header">
    <a class="navbar-brand" href="#/">
      <img [src]="siteicon" />
    </a>
    <!-- <h3 style="margin-top: -5px; font-size: 20px">{{siteurl}}</h3> -->
  </div>

  <div class="" style="margin-top:15%;">
    <div id="loginbox" class="col-sm-8 offset-sm-2 col-xs-12 mainbox">

      <div class="card-block p-2 card">
        <div *ngIf="etape==1" class="col-12">
          <div class="">
            <p>Un Email contenant un code de confirmation va vous être envoyé à l'adresse suivante:</p>
          </div>
          <div class="col">
            <form role="form" name="form">
              <div class="form-group">
                <div style="margin-bottom: 25px" class="mb-3 input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-user"></i></span>
                  </div>
                  <input type="email" name="username" class="form-control" [(ngModel)]="username" required email
                    #usernameRef [ngClass]="{ 'is-invalid': usernameRef.invalid }" />
                </div>
                <span *ngIf="usernameRef.dirty && usernameRef.errors?.required" class="text-danger">Champ
                  obligatoire</span>
                <span *ngIf="usernameRef.dirty && usernameRef.errors?.email" class="text-danger">Email invalide</span>
              </div>
              <div class="form-actions">
                <button class="btn btn-success" (click)="pwdRequestCode()">Demander un code</button>
                <button class="btn btn-default" (click)="pwdBackLogin()">Annuler</button>
              </div>
            </form>
          </div>
        </div>

        <div *ngIf="etape==2" class="col-12">
          <div class="">
            <p>Un code de confirmation vous a été envoyé par Email à l'adresse suivante: {{username}}.<br>Merci de renseigner ce code</p>
          </div>
          <form role="form" name="form">
            <div class="form-group">
              <div style="margin-bottom: 25px" class="mb-3 input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-code"></i></span>
                </div>
                <input type="text" name="code" class="form-control" [(ngModel)]="code" required #codeRef />
              </div>
              <span *ngIf="codeRef.dirty && codeRef.errors?.required" class="text-danger">Champ
                obligatoire</span>
            </div>
            <div class="form-actions">
              <button class="btn btn-success" (click)="pwdCheckCode()">Valider le code</button>
              <button class="btn btn-default" (click)="pwdBackLogin()">Annuler</button>
            </div>
          </form>
        </div>

        <div *ngIf="etape==3">
          <div class="">
            <p>Saisissez votre nouveau mot de passe:</p>
          </div>
          <form role="form" name="form">
            <div class="form-group">
              <div style="margin-bottom: 25px" class="mb-3 input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-unlock"></i></span>
                </div>
                <input type="password" name="password1" class="form-control" [(ngModel)]="newpassword1" required
                  #password1Ref />
              </div>
              <span *ngIf="password1Ref.dirty && password1Ref.errors?.required" class="text-danger">Champ
                obligatoire</span>
              <div style="margin-bottom: 25px" class="mb-3 input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-lock"></i></span>
                </div>
                <input type="password" name="password2" class="form-control" [(ngModel)]="newpassword2" required
                  #password2Ref />
              </div>
              <span *ngIf="password2Ref.dirty && newpassword1 !== newpassword2" class="text-danger">Les mots de passe ne correspondent pas</span>
            </div>
            <div class="form-actions">
              <button class="btn btn-success" (click)="pwdChange()">Modifer</button>
              <button class="btn btn-default" (click)="pwdBackLogin()">Annuler</button>
            </div>
          </form>
        </div>
      </div>


    </div>
  </div>
</div>
